import {
    ITemplateNotification,
    TemplateNotificationType,
    ITemplateNotificationCreateDto,
    ITemplateNotificationUpdateDto,
} from "@lib";
import { Modal } from "@core/components/alt-ui/modal";
import { Button, Control, Panel, TextArea, TextBox } from "@core/components/alt-ui/controls";

export class SettingsTemplatesSmsModal extends Modal<ITemplateNotification> {
    private tbName!: TextBox;
    private tbDescription!: TextBox;
    private taTemplate!: TextArea;
    private pnlFooter!: Panel;
    private btnCancel!: Button;
    private btnSave!: Button;

    private sms: ITemplateNotification | null = null;
    public onCreate: ((dto: ITemplateNotificationCreateDto) => Promise<ITemplateNotification | null>) | null = null;
    public onUpdate:
        | ((sms: ITemplateNotification, dto: ITemplateNotificationUpdateDto) => Promise<ITemplateNotification | null>)
        | null = null;

    public constructor() {
        super("sms-modal", "");
        this.initializeControls();
    }

    public show(sms?: ITemplateNotification): Promise<void> {
        this.sms = sms ?? null;
        this.title = sms ? "Изменение СМС" : "Новое СМС";
        this.initializeControls();

        if (sms) {
            this.populateControls(sms);
        }

        return super.show();
    }

    private initializeControls(): void {
        this.tbName = new TextBox();
        this.tbDescription = new TextBox();
        this.taTemplate = new TextArea();
        this.pnlFooter = new Panel();
        this.btnCancel = new Button();
        this.btnSave = new Button();

        //

        this.tbName.id = "sms.name";
        this.tbName.label = "Название";
        this.tbName.validation = "required";
        this.tbName.text = "";

        this.tbDescription.id = "sms.description";
        this.tbDescription.label = "Описание";
        this.tbDescription.text = "";

        this.taTemplate.id = "sms.template";
        this.taTemplate.label = "Шаблон";
        this.taTemplate.text = "%(Устройство.Название) готов. %(Филиал.Название)";

        //

        this.btnCancel.id = "sms.cancel";
        this.btnCancel.text = "Отменить";
        this.btnCancel.class = "mr-0.75";
        this.btnCancel.variant = "outline-danger";
        this.btnCancel.addClickHandler(this.clickCancel.bind(this));

        this.btnSave.id = "sms.save";
        this.btnSave.text = "Сохранить";
        this.btnSave.addClickHandler(this.clickSave.bind(this));

        this.pnlFooter.class = "flex justify-end mt-2";
        this.pnlFooter.addControl(this.btnCancel);
        this.pnlFooter.addControl(this.btnSave);
    }

    private populateControls(sms: ITemplateNotification): void {
        this.tbName.text = sms.name;
        this.tbDescription.text = sms.description ?? "";
        this.taTemplate.text = sms.template;
    }

    public get controls(): Control[] {
        return [this.tbName, this.tbDescription, this.taTemplate, this.pnlFooter];
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide();
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();
        if (!valid) {
            return;
        }

        const result = this.sms ? await this.updateSms(this.sms) : await this.createSms();

        if (result) {
            this.hide(result);
        }
    }

    private async createSms(): Promise<ITemplateNotification | null> {
        if (!this.onCreate) {
            return null;
        }

        const dto: ITemplateNotificationCreateDto = {
            type: TemplateNotificationType.SmsGeneral,
            name: this.tbName.text,
            description: this.tbDescription.text,
            template: this.taTemplate.text,
            //offices: [],
            //tags: [],
        };

        return await this.onCreate(dto);
    }

    private async updateSms(sms: ITemplateNotification): Promise<ITemplateNotification | null> {
        if (!this.onUpdate) {
            return null;
        }

        const dto: ITemplateNotificationUpdateDto = {
            name: this.tbName.text,
            description: this.tbDescription.text,
            template: this.taTemplate.text,
            //offices: [],
            //tags: [],
        };

        return await this.onUpdate(sms, dto);
    }
}
