import { Vue, Component } from "vue-property-decorator";
import { BCard, BButton } from "bootstrap-vue";
import {
    ICompany,
    ITemplateNotification,
    ITemplateNotificationCreateDto,
    ITemplateNotificationUpdateDto,
    IUser,
} from "@lib";
import { PermissionCommonSection, PermissionRight } from "@lib";
import AltTable from "@/core/components/alt-table";
import { ModalComponent } from "@core/components/alt-ui/modal";
import { SettingsTemplatesSmsModal } from "./settings-templates-sms.modal";
import { getTableColumns } from "./settings-templates-sms-defaults";

@Component({
    components: { BCard, BButton, AltTable, ModalComponent },
})
export default class SettingsTemplatesSms extends Vue {
    private user!: IUser;
    private company!: ICompany;
    private smss: ITemplateNotification[] = [];

    private SmsUseCase = this.$alt.system.usecase.createTemplateNotificationUseCase();

    private smsModal: SettingsTemplatesSmsModal;

    public constructor() {
        super();

        this.smsModal = new SettingsTemplatesSmsModal();
        this.smsModal.onCreate = this.create.bind(this);
        this.smsModal.onUpdate = this.update.bind(this);
    }

    private get columns(): any[] {
        return getTableColumns(this);
    }

    private get can(): any {
        const secure = this.$secure;
        return {
            get create(): boolean {
                return secure.checkCommon(PermissionCommonSection.Templates, PermissionRight.Create);
            },
            get read(): boolean {
                return true;
            },
            get update(): boolean {
                return secure.checkCommon(PermissionCommonSection.Templates, PermissionRight.Update);
            },
            get delete(): boolean {
                return secure.checkCommon(PermissionCommonSection.Templates, PermissionRight.Delete);
            },
        };
    }

    public async mounted(): Promise<void> {
        try {
            this.$alt.loader.show();
            this.user = await this.$info.getUser();
            this.company = await this.$info.getCompany();
            await this.selectSms();
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async openModalCreate(): Promise<void> {
        await this.smsModal.show();
    }

    private async openModalUpdate(sms: ITemplateNotification): Promise<void> {
        await this.smsModal.show(sms);
    }

    private async confirmCopy(sms: ITemplateNotification): Promise<void> {
        const result = await this.$alt.message.confirm(`Скопировать смс "${sms.name}"?`, "Копирование смс", {
            acceptText: "Скопировать",
            color: "primary",
        });

        if (result) {
            await this.copy(sms);
        }
    }

    private async confirmDelete(sms: ITemplateNotification): Promise<void> {
        const result = await this.$alt.message.confirm(
            `Вы уверены, что хотите удалить смс: "${sms.name}"?`,
            "Удаление смс",
            { acceptText: "Удалить" },
        );

        if (result) {
            await this.delete(sms);
        }
    }

    private async selectSms(): Promise<void> {
        try {
            if (!this.can.read) {
                return;
            }

            this.smss = await this.SmsUseCase.select(this.company.id);
        } catch (e: any) {
            throw new Error(`Не удалось загрузить смс:\n${e.message}`);
        }
    }

    private async create(dto: ITemplateNotificationCreateDto): Promise<ITemplateNotification | null> {
        try {
            this.$alt.loader.show();
            const s = await this.SmsUseCase.create(this.company.id, dto);
            await this.selectSms();
            this.$alt.toast.success("СМС успешно создано.", "Создание");
            return s;
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось создать смс:\n${e.message}`);
            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async update(
        sms: ITemplateNotification,
        dto: ITemplateNotificationUpdateDto,
    ): Promise<ITemplateNotification | null> {
        try {
            this.$alt.loader.show();
            const s = await this.SmsUseCase.update(this.company.id, sms.id, dto);
            await this.selectSms();
            this.$alt.toast.success(`СМС "${sms.name}" успешно изменено.`, "Изменение");
            return s;
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось изменить смс:\n${e.message}`);
            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async copy(sms: ITemplateNotification): Promise<void> {
        try {
            this.$alt.loader.show();
            const dto: ITemplateNotificationCreateDto = {
                type: sms.type,
                name: sms.name + " - копия",
                description: sms.description,
                template: sms.template,
                offices: sms.offices as string[],
                tags: sms.tags,
            };
            await this.SmsUseCase.create(this.company.id, dto);
            await this.selectSms();
            this.$alt.toast.success(`СМС "${sms.name}" успешно скопировано.`, "Копирование");
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось скопировать смс:\n${e.message}`);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async delete(sms: ITemplateNotification): Promise<void> {
        try {
            this.$alt.loader.show();
            await this.SmsUseCase.archive(this.company.id, sms.id);
            await this.selectSms();
            this.$alt.toast.success(`СМС "${sms.name}" успешно удалено.`, "Удаление");
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось удалить смс:\n${e.message}`);
        } finally {
            this.$alt.loader.hide();
        }
    }
}
