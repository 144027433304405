import { ITemplateLabel, TemplateLabelType } from "@lib";
import { ISelectOption } from "@core/types/common/select-options";

export function getTemplateLabelTypes(): ISelectOption<TemplateLabelType>[] {
    return [{ id: TemplateLabelType.GoodLabel, name: "Ценник товара" }];
}

export function getTableColumns(context: any, clickTemplateFunc: Function): any[] {
    return [
        {
            colId: "actions",
            headerName: "Изменить/удалить",
            width: 90,
            hide: !context.can.update && !context.can.create && !context.can.delete,
            headerClass: "text-transparent",

            cellRendererFramework: "CellRendererActions",
            cellRendererParams: (params: any) => {
                return {
                    actions: [
                        {
                            id: "edit",
                            description: "Изменить",
                            icon: "Edit3Icon",
                            classes: "h-1 w-1 mr-0.75 hover:text-primary cursor-pointer",
                            isVisible: () => context.can.update,
                            click: (label: ITemplateLabel) => context.openModalUpdate(label),
                        },
                        {
                            id: "copy",
                            description: "Скопировать",
                            icon: "CopyIcon",
                            classes: "h-1 w-1 mr-0.75 hover:text-primary cursor-pointer",
                            isVisible: () => context.can.create,
                            click: (label: ITemplateLabel) => context.confirmCopy(label),
                        },
                        {
                            id: "delete",
                            description: "Удалить",
                            icon: "Trash2Icon",
                            classes: "h-1 w-1 mr-0.75 hover:text-danger cursor-pointer",
                            isVisible: () => context.can.delete,
                            click: (label: ITemplateLabel) => context.confirmDelete(label),
                        },
                    ],
                };
            },
        },
        {
            colId: "template",
            headerName: "Шаблон",
            width: 140,
            hide: false,
            headerClass: "text-transparent",

            cellRendererFramework: "CellRendererChip",
            cellRendererParams: (params: any) => {
                return {
                    color: "primary",
                    name: "Шаблон",
                    icon: "Edit2Icon",
                    //click: () => { $router.push({ name: "clients", params: { id: client.id } }).catch(() => {}); }
                    click: () => clickTemplateFunc(params.data),
                };
            },
        },
        {
            colId: "number",
            field: "number",
            headerName: "#",
            sortable: true,
            filter: true,
            width: 70,
            hide: false,
        },
        {
            colId: "type",
            field: "type",
            headerName: "Тип",
            sortable: true,
            filter: true,
            width: 200,
            hide: false,

            cellRendererFramework: "CellRendererDynamic",
            cellRendererParams: (params: any) => {
                const elements: any[] = [];
                const type = getTemplateLabelTypes().find(type => type.id === params.value);

                if (type) {
                    if (type.id === TemplateLabelType.GoodLabel) {
                        elements.push({ type: "feather-icon", icon: "TagIcon", class: "mr-0.5" });
                    }

                    elements.push({ type: "text", text: type.name });
                } else {
                    elements.push({ type: "text", text: "-" });
                }

                return { elements };
            },
        },
        {
            colId: "name",
            field: "name",
            headerName: "Название",
            sortable: true,
            filter: true,
            width: 250,
            hide: false,
        },
        {
            colId: "description",
            field: "description",
            headerName: "Описание",
            sortable: true,
            filter: true,
            width: 300,
            hide: false,
        },
    ];
}

//

export function getGoodLabelTemplates(): any[] {
    return [
        {
            id: "blank",
            name: "Пустой шаблон",
        },
        {
            id: "good-price",
            name: "Шаблон ценника",
        },
        {
            id: "good-price-barcode",
            name: "Шаблон ценника со штрихкодом",
        },
        {
            id: "good-barcode",
            name: "Шаблон только штрихкода",
        },
    ];
}
