import { ITemplateDocument, TemplateDocumentType, ITemplateDocumentUpdateTemplateDto, ICompany } from "@lib";
import { Control } from "@core/components/alt-ui/controls";
import { Footer } from "@core/controls/footer.control";
import { DocumentTemplateEditor } from "@core/controls/document-template-editor/document-template-editor";
import { Sidebar } from "@core/components/alt-ui/sidebar";
import {
    createDemoOffice,
    createDemoOrder,
    createDemoOrderType,
    createDemoSale,
    createDemoShop,
    createProductType,
} from "@core/controls/document-template-editor/preview-data";
import { orderMacroAutocomplete } from "@core/usecases/template/macro-replacers/order-document.macro-list";
import { saleMacroAutocomplete } from "@core/usecases/template/macro-replacers/sale-document.macro-list";
import { Printer } from "@/@core/usecases/template/printer";
import { OrderPrintContext } from "@core/usecases/template/macro-replacers/order-document.macro-replacer";
import { SalePrintContext } from "@core/usecases/template/macro-replacers/sale-document.macro-replacer";

export interface ITemplateDocumentModalContext {
    document: ITemplateDocument;
    company: ICompany;
}

export class SettingsTemplatesDocumentsEditModal extends Sidebar<ITemplateDocumentModalContext> {
    private editor!: DocumentTemplateEditor;
    private footerPanel!: Footer;

    private context: ITemplateDocumentModalContext | null = null;
    public onEdit:
        | ((document: ITemplateDocument, dto: ITemplateDocumentUpdateTemplateDto) => Promise<ITemplateDocument | null>)
        | null = null;

    public constructor() {
        super("documents-edit-modal", "");
        this.initializeControls();
    }

    public show(context: ITemplateDocumentModalContext): Promise<void> {
        this.context = context;
        this.title = `Шаблон ${this.context.document.name}`;
        this.initializeControls();
        this.populateControls(this.context.document);
        return super.show();
    }

    private initializeControls(): void {
        this.editor = new DocumentTemplateEditor();
        this.footerPanel = new Footer({
            reverse: true,
            alignement: "start",
            okHandler: this.clickSave.bind(this),
            cancelHandler: this.clickCancel.bind(this),
        });
    }

    private populateControls(document: ITemplateDocument): void {
        if (!this.context) {
            return;
        }

        this.editor.text = document.template;

        const baseUrl = process.env.VUE_APP_DOCS_BASEURL;

        if (document.type === TemplateDocumentType.OfficeGeneral) {
            const context: OrderPrintContext = {
                company: this.context.company,
                office: createDemoOffice(),
                order: createDemoOrder(),
                orderType: createDemoOrderType(),
                productTypes: [createProductType()],
            };

            this.editor.preview = template => Printer.previewOrderDocuments(template, context, document);
            this.editor.getPreviewHtml = template => Printer.getPreviewHtmlOrderDocuments(template, context, document);

            this.editor.macroAutocompletes = orderMacroAutocomplete;
            this.editor.macrosDocsLink = `${baseUrl}guide/features/documents/#доступные-макросы-для-заявок`;
        } else if (document.type === TemplateDocumentType.ShopDocument) {
            const context: SalePrintContext = {
                company: this.context.company,
                shop: createDemoShop(),
                sale: createDemoSale(),
            };

            this.editor.preview = template => Printer.previewSaleDocuments(template, context, document);
            this.editor.getPreviewHtml = template => Printer.getPreviewHtmlSaleDocuments(template, context, document);

            this.editor.macroAutocompletes = saleMacroAutocomplete;
            this.editor.macrosDocsLink = `${baseUrl}guide/features/documents/#доступные-макросы-для-продаж`;
        } else {
            const context: OrderPrintContext = {
                company: this.context.company,
                office: createDemoOffice(),
                order: createDemoOrder(),
                orderType: createDemoOrderType(),
                productTypes: [createProductType()],
            };

            this.editor.getPreviewHtml = template => Printer.getPreviewHtmlOrderDocuments(template, context, document);
        }
    }

    public get controls(): Control[] {
        return [this.editor];
    }

    public get footerControls(): Control[] {
        return [this.footerPanel];
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide();
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();
        if (!valid || !this.context?.document) {
            return;
        }

        await this.editDocument(this.context.document);

        // не закрываем окно после сохранения
    }

    private async editDocument(document: ITemplateDocument): Promise<ITemplateDocument | null> {
        if (!this.onEdit) {
            return null;
        }

        const dto: ITemplateDocumentUpdateTemplateDto = {
            template: this.editor.template ?? "",
        };

        return await this.onEdit(document, dto);
    }
}
