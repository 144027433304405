import { ITemplateDocument, TemplateDocumentType } from "@lib";
import { ISelectOption } from "@core/types/common/select-options";

export function getDocumentTypes(): ISelectOption<TemplateDocumentType>[] {
    return [
        { id: TemplateDocumentType.OfficeGeneral, name: "Документ филиала" },
        { id: TemplateDocumentType.ShopDocument, name: "Документ магазина" },
    ];
}

export function getTableColumns(context: any, clickTemplateFunc: Function): any[] {
    return [
        {
            colId: "actions",
            headerName: "Изменить/удалить",
            width: 90,
            hide: !context.can.update && !context.can.create && !context.can.delete,
            headerClass: "text-transparent",

            cellRendererFramework: "CellRendererActions",
            cellRendererParams: (params: any) => {
                return {
                    actions: [
                        {
                            id: "edit",
                            description: "Изменить",
                            icon: "Edit3Icon",
                            classes: "h-1 w-1 mr-0.75 hover:text-primary cursor-pointer",
                            isVisible: () => context.can.update,
                            click: (document: ITemplateDocument) => context.openModalUpdate(document),
                        },
                        {
                            id: "copy",
                            description: "Скопировать",
                            icon: "CopyIcon",
                            classes: "h-1 w-1 mr-0.75 hover:text-primary cursor-pointer",
                            isVisible: () => context.can.create,
                            click: (document: ITemplateDocument) => context.confirmCopy(document),
                        },
                        {
                            id: "delete",
                            description: "Удалить",
                            icon: "Trash2Icon",
                            classes: "h-1 w-1 mr-0.75 hover:text-danger cursor-pointer",
                            isVisible: () => context.can.delete,
                            click: (document: ITemplateDocument) => context.confirmDelete(document),
                        },
                    ],
                };
            },
        },
        {
            colId: "template",
            headerName: "Шаблон",
            width: 140,
            hide: false,
            headerClass: "text-transparent",

            cellRendererFramework: "CellRendererChip",
            cellRendererParams: (params: any) => {
                return {
                    color: "primary",
                    name: "Шаблон",
                    icon: "Edit2Icon",
                    //click: () => { $router.push({ name: "clients", params: { id: client.id } }).catch(() => {}); }
                    click: () => clickTemplateFunc(params.data),
                };
            },
        },
        {
            colId: "number",
            field: "number",
            headerName: "#",
            sortable: true,
            filter: true,
            width: 70,
            hide: false,
        },
        {
            colId: "type",
            field: "type",
            headerName: "Тип",
            sortable: true,
            filter: true,
            width: 200,
            hide: false,

            cellRendererFramework: "CellRendererDynamic",
            cellRendererParams: (params: any) => {
                const elements: any[] = [];
                const type = getDocumentTypes().find(type => type.id === params.value);

                if (type) {
                    let color = "";
                    if (type.id === TemplateDocumentType.OfficeGeneral) {
                        color = "text-primary";
                        elements.push({ type: "alt-icon", icon: "clipboard-chart", class: `w-1.25 mr-0.5 ${color}` });
                    } else if (type.id === TemplateDocumentType.ShopDocument) {
                        color = "text-success";
                        elements.push({ type: "feather-icon", icon: "ShoppingCartIcon", class: `mr-0.5 ${color}` });
                    }

                    elements.push({ type: "text", text: type.name, class: color });
                } else {
                    elements.push({ type: "text", text: "-" });
                }

                return { elements };
            },
        },
        {
            colId: "name",
            field: "name",
            headerName: "Название",
            sortable: true,
            filter: true,
            width: 250,
            hide: false,
        },
        {
            colId: "description",
            field: "description",
            headerName: "Описание",
            sortable: true,
            filter: true,
            width: 300,
            hide: false,
        },
    ];
}

//

export function getOfficeGeneratTemplates(): any[] {
    return [
        {
            id: "blank",
            name: "Пустой документ",
        },
        {
            id: "office-receipt",
            name: "Шаблон квитанции",
        },
        {
            id: "office-act",
            name: "Шаблон акта выполненных работ",
        },
        {
            id: "office-check",
            name: "Шаблон товарного чека",
        },
        {
            id: "office-warranty",
            name: "Шаблон гарантийного талона",
        },
    ];
}

export function getShopGeneratTemplates(): any[] {
    return [
        {
            id: "blank",
            name: "Пустой документ",
        },
        {
            id: "shop-check",
            name: "Шаблон товарного чека",
        },
        {
            id: "shop-warranty",
            name: "Шаблон гарантийного талона",
        },
    ];
}
