import { Vue, Component } from "vue-property-decorator";
import { BCard, BButton } from "bootstrap-vue";
import { ICompany, ITemplateDocument, ITemplateDocumentUpdateDto, IUser } from "@lib";
import { ITemplateDocumentCreateDto, ITemplateDocumentCreateFromTemplateDto } from "@lib";
import { PermissionCommonSection, PermissionRight } from "@lib";
import AltTable from "@/core/components/alt-table";
import { ModalComponent } from "@core/components/alt-ui/modal";
import { SidebarComponent } from "@core/components/alt-ui/sidebar/sidebar.component";
import { SettingsTemplatesDocumentsModal } from "./settings-templates-documents.modal";
import { getTableColumns } from "./settings-templates-documents-defaults";
import { SettingsTemplatesDocumentsEditModal } from "./settings-templates-documents-edit.modal";

@Component({
    components: { BCard, BButton, AltTable, ModalComponent, SidebarComponent },
})
export default class SettingsTemplatesDocuments extends Vue {
    private user!: IUser;
    private company!: ICompany;
    private documents: ITemplateDocument[] = [];

    private DocumentUseCase = this.$alt.system.usecase.createTemplateDocumentUseCase();

    private documentModal: SettingsTemplatesDocumentsModal;
    private documentEditModal: SettingsTemplatesDocumentsEditModal;

    public constructor() {
        super();

        this.documentModal = new SettingsTemplatesDocumentsModal();
        this.documentEditModal = new SettingsTemplatesDocumentsEditModal();

        this.documentModal.onCreate = this.create.bind(this);
        this.documentModal.onUpdate = this.update.bind(this);
        this.documentModal.onEditClick = this.openModalEditTemplate.bind(this);

        this.documentEditModal.onEdit = this.update.bind(this);
    }

    private get columns(): any[] {
        return getTableColumns(this, this.openModalEditTemplate);
    }

    private get can(): any {
        const secure = this.$secure;
        return {
            get create(): boolean {
                return secure.checkCommon(PermissionCommonSection.Templates, PermissionRight.Create);
            },
            get read(): boolean {
                return true;
            },
            get update(): boolean {
                return secure.checkCommon(PermissionCommonSection.Templates, PermissionRight.Update);
            },
            get delete(): boolean {
                return secure.checkCommon(PermissionCommonSection.Templates, PermissionRight.Delete);
            },
        };
    }

    public async mounted(): Promise<void> {
        try {
            this.$alt.loader.show();
            this.user = await this.$info.getUser();
            this.company = await this.$info.getCompany();
            await this.selectDocuments();
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async openModalCreate(): Promise<void> {
        await this.documentModal.show();
    }

    private async openModalUpdate(document: ITemplateDocument): Promise<void> {
        await this.documentModal.show(document);
    }

    private async openModalEditTemplate(document: ITemplateDocument): Promise<void> {
        this.documentEditModal.show({
            document: document,
            company: this.company,
        });
    }

    private async confirmCopy(document: ITemplateDocument): Promise<void> {
        const result = await this.$alt.message.confirm(
            `Скопировать документ "${document.name}"?`,
            "Копирование документа",
            { acceptText: "Скопировать", color: "primary" },
        );

        if (result) {
            await this.copy(document);
        }
    }

    private async confirmDelete(document: ITemplateDocument): Promise<void> {
        const result = await this.$alt.message.confirm(
            `Вы уверены, что хотите удалить документ: "${document.name}"?`,
            "Удаление документа",
            { acceptText: "Удалить" },
        );

        if (result) {
            await this.delete(document);
        }
    }

    private async selectDocuments(): Promise<void> {
        try {
            if (!this.can.read) {
                return;
            }

            this.documents = await this.DocumentUseCase.select(this.company.id);
        } catch (e: any) {
            throw new Error(`Не удалось загрузить документы:\n${e.message}`);
        }
    }

    private async create(dto: ITemplateDocumentCreateFromTemplateDto): Promise<ITemplateDocument | null> {
        try {
            this.$alt.loader.show();
            const doc = await this.DocumentUseCase.createFromTemplate(this.company.id, dto);
            await this.selectDocuments();
            this.$alt.toast.success("Документ успешно создан.", "Создание");
            return doc;
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось создать документ:\n${e.message}`);
            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async update(
        document: ITemplateDocument,
        dto: ITemplateDocumentUpdateDto,
    ): Promise<ITemplateDocument | null> {
        try {
            this.$alt.loader.show();
            const doc = await this.DocumentUseCase.update(this.company.id, document.id, dto);
            await this.selectDocuments();
            this.$alt.toast.success(`Документ "${document.name}" успешно изменён.`, "Изменение");
            return doc;
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось изменить документ:\n${e.message}`);
            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async copy(document: ITemplateDocument): Promise<void> {
        try {
            this.$alt.loader.show();
            const dto: ITemplateDocumentCreateDto = {
                type: document.type,
                name: document.name + " - копия",
                description: document.description,
                template: document.template,
                offices: document.offices as string[],
                tags: document.tags,
            };
            await this.DocumentUseCase.create(this.company.id, dto);
            await this.selectDocuments();
            this.$alt.toast.success(`Документ "${document.name}" успешно скопирован.`, "Копирование");
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось скопировать документ:\n${e.message}`);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async delete(document: ITemplateDocument): Promise<void> {
        try {
            this.$alt.loader.show();
            await this.DocumentUseCase.archive(this.company.id, document.id);
            await this.selectDocuments();
            this.$alt.toast.success(`Документ "${document.name}" успешно удалён.`, "Удаление");
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось удалить документ:\n${e.message}`);
        } finally {
            this.$alt.loader.hide();
        }
    }
}
