import { Vue, Component } from "vue-property-decorator";
import { BCard, BButton } from "bootstrap-vue";
import { ICompany, ITemplateLabel, ITemplateLabelUpdateDto, IUser } from "@lib";
import { ITemplateLabelCreateDto, ITemplateLabelCreateFromTemplateDto } from "@lib";
import { PermissionCommonSection, PermissionRight } from "@lib";
import AltTable from "@/core/components/alt-table";
import { ModalComponent } from "@core/components/alt-ui/modal";
import { SidebarComponent } from "@core/components/alt-ui/sidebar/sidebar.component";
import { SettingsTemplatesLabelsModal } from "./settings-templates-labels.modal";
import { getTableColumns } from "./settings-templates-labels-defaults";
import { SettingsTemplatesLabelsEditModal } from "./settings-templates-labels-edit.modal";

@Component({
    components: { BCard, BButton, AltTable, ModalComponent, SidebarComponent },
})
export default class SettingsTemplatesLabels extends Vue {
    private user!: IUser;
    private company!: ICompany;
    private labels: ITemplateLabel[] = [];

    private LabelUseCase = this.$alt.system.usecase.createTemplateLabelUseCase();

    private labelModal: SettingsTemplatesLabelsModal;
    private labelEditModal: SettingsTemplatesLabelsEditModal;

    public constructor() {
        super();

        this.labelModal = new SettingsTemplatesLabelsModal();
        this.labelEditModal = new SettingsTemplatesLabelsEditModal();

        this.labelModal.onCreate = this.create.bind(this);
        this.labelModal.onUpdate = this.update.bind(this);
        this.labelModal.onEditClick = this.openModalEditTemplate.bind(this);

        this.labelEditModal.onEdit = this.update.bind(this);
    }

    private get columns(): any[] {
        return getTableColumns(this, this.openModalEditTemplate);
    }

    private get can(): any {
        const secure = this.$secure;
        return {
            get create(): boolean {
                return secure.checkCommon(PermissionCommonSection.Templates, PermissionRight.Create);
            },
            get read(): boolean {
                return true;
            },
            get update(): boolean {
                return secure.checkCommon(PermissionCommonSection.Templates, PermissionRight.Update);
            },
            get delete(): boolean {
                return secure.checkCommon(PermissionCommonSection.Templates, PermissionRight.Delete);
            },
        };
    }

    public async mounted(): Promise<void> {
        try {
            this.$alt.loader.show();
            this.user = await this.$info.getUser();
            this.company = await this.$info.getCompany();
            await this.selectLabels();
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async openModalCreate(): Promise<void> {
        await this.labelModal.show();
    }

    private async openModalUpdate(label: ITemplateLabel): Promise<void> {
        await this.labelModal.show(label);
    }

    private async openModalEditTemplate(label: ITemplateLabel): Promise<void> {
        this.labelEditModal.show({
            label: label,
            company: this.company,
        });
    }

    private async confirmCopy(label: ITemplateLabel): Promise<void> {
        const result = await this.$alt.message.confirm(`Скопировать ценник "${label.name}"?`, "Копирование ценника", {
            acceptText: "Скопировать",
            color: "primary",
        });

        if (result) {
            await this.copy(label);
        }
    }

    private async confirmDelete(label: ITemplateLabel): Promise<void> {
        const result = await this.$alt.message.confirm(
            `Вы уверены, что хотите удалить ценник: "${label.name}"?`,
            "Удаление ценника",
            { acceptText: "Удалить" },
        );

        if (result) {
            await this.delete(label);
        }
    }

    private async selectLabels(): Promise<void> {
        try {
            if (!this.can.read) {
                return;
            }

            this.labels = await this.LabelUseCase.select(this.company.id);
        } catch (e: any) {
            throw new Error(`Не удалось загрузить ценник:\n${e.message}`);
        }
    }

    private async create(dto: ITemplateLabelCreateFromTemplateDto): Promise<ITemplateLabel | null> {
        try {
            this.$alt.loader.show();
            const label = await this.LabelUseCase.createFromTemplate(this.company.id, dto);
            await this.selectLabels();
            this.$alt.toast.success("Ценник успешно создан.", "Создание");
            return label;
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось создать ценник:\n${e.message}`);
            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async update(orig: ITemplateLabel, dto: ITemplateLabelUpdateDto): Promise<ITemplateLabel | null> {
        try {
            this.$alt.loader.show();
            const label = await this.LabelUseCase.update(this.company.id, orig.id, dto);
            await this.selectLabels();
            this.$alt.toast.success(`Ценник "${orig.name}" успешно изменён.`, "Изменение");
            return label;
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось изменить ценник:\n${e.message}`);
            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async copy(label: ITemplateLabel): Promise<void> {
        try {
            this.$alt.loader.show();
            const dto: ITemplateLabelCreateDto = {
                type: label.type,
                name: label.name + " - копия",
                description: label.description,
                width: label.width,
                height: label.height,
                template: label.template,
                stores: label.stores as string[],
                tags: label.tags,
            };
            await this.LabelUseCase.create(this.company.id, dto);
            await this.selectLabels();
            this.$alt.toast.success(`Ценник "${label.name}" успешно скопирован.`, "Копирование");
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось скопировать ценник:\n${e.message}`);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async delete(label: ITemplateLabel): Promise<void> {
        try {
            this.$alt.loader.show();
            await this.LabelUseCase.archive(this.company.id, label.id);
            await this.selectLabels();
            this.$alt.toast.success(`Ценник "${label.name}" успешно удалён.`, "Удаление");
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось удалить ценник:\n${e.message}`);
        } finally {
            this.$alt.loader.hide();
        }
    }
}
